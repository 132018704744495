body {
  height: 100%;
  width: 100%;
  background: #565656;
  background-image: url('../src/assets/Sportsbay_Vendor_Web_Panel_Assets/bg.png');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


.main-body {
  padding: 1rem;
}

::placeholder {
  font-size: 14px;
}

.mat-icon {
  font-size: 20px;
  cursor: pointer;
}

input,
textarea {
  padding:0 10px;
}

/* manage court button */


.form-select {
 
  padding: 0px !important;
  
}

/* manage court button */

/* authentication */
/* =================== Auth css================================= */
.btnn {
  width: 100%;
  padding: 4px;
  border-radius: 5px;
  background-color: #338A2A;
  color: #fff;
  background-position: 0%;
  -webkit-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  font-weight: 500;
  font-size: 15px;
 
  border: 1px solid transparent;
  cursor: pointer;
}


.btns {
  /* width: 100%; */
  padding: 4px;
  border-radius: 5px;
  background-color: #338A2A;
  color: #fff;
  background-position: 0%;
  -webkit-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  font-weight: 500;
  font-size: 15px;
 
  border: 1px solid transparent;
  cursor: pointer;
}

.btns:hover {
  color: #000;
}

.inner-mdiv {

  width: 100%;
  border-radius: 10px;
}

.set-i {
  margin-left: -40px !important;
}

.set-icon {
  text-align: center;
}


.set-title {
  text-align: center;
  font-size: 1.8rem;
  font-stretch: semi-expanded;
  font-weight: 800;
  margin-bottom: 2rem !important;
  font-family: Georgia, serif;
  color: #338A2A;
  cursor: default;
}

.form-fld {
  padding: 1.5rem 3.5rem;
}

.amain-div {
  height: 450px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
}


.inner-mdiv {
  background-color: #fff;
  width: 100%;
  border-radius: 5px;
}

.set-i {
  margin-left: -40px !important;
}

.set-icon {
  text-align: center;
}


.mtext {
  color: #0c0c0c;
  font-size: 14px;
  background: none;
 
  cursor: pointer;
  border: none;
}


/* ============media quree======= */
@media (min-width:320px) and (max-width:359px) {
  .amain-div {
    width: 100%;
    left: 54%;
  }

  .form-fld {
    padding: 0 !important;
    text-align: center;
  }

  .modal.show .modal-dialog {
    transform: matrix(1, 0, 0, 1, 17, 0) !important;
  }

  .mdl-w {
    width: 90% !important;
  }

  .modal-title {
    font-family: auto !important;
    font-size: 14px !important;
  }

  .chart-outer {
    width: 100% !important;
  }

  .set-c-name {

    font-size: .9rem !important;

  }


  .dash-card {
    height: 130px !important;
    margin: 0px !important;
    width: 100% !important;
  }

  .header {
    height: 45px !important;
    padding: .5rem !important;
    

  }

  .f-header {
    margin: 0 !important;
    padding: 0 !important;
    flex-wrap: wrap;
    gap: 5px;
  }

  .inner-mdiv {
    padding: 0%;
  }

  .dash-parent {

    margin: 0rem !important;
    padding: 0rem !important;
  }

  .seth4 {
    margin: 0px !important;
  }

  .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
    padding-top: 8px !important;
    font-size: 10px;
  }

  .layout1 {
    padding: 0px !important;
  }

  .content {
    border: none;
    margin: 5px;
    padding: 10px !important;
  }

  .my-date .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    width: 132px;
  }

  .img {
    width: 115px;
  }

  .btnn {
    font-size: 14px;
    width: 75%;
  }

  .ground1 {
    gap: 0 !important;
  }

  .inpt {
    height: 35px;
    /* width: 190px; */
  }

  .main-d {

    width: 220px !important;
  }

  .admin-p {
    display: none !important;
  
   }
   .mat-mdc-paginator-container{
    padding: 0 !important;
   }


   .mdc-text-field--outlined .mdc-notched-outline {
       height: 35px;
   }

}


@media (min-width:375px) and (max-width:417px) {
  /* .amain-div {
    width: 60% !important;
  } */

}

@media (min-width:359px) and (max-width:748px) {

  /* .amain-div {
    width: 60% !important;
  } */
  .btnn {
    font-size: 14px;
    width: 65%;
  }
}

@media (min-width:748px) and (max-width:1024px) {
  .amain-div {
    width: 48%;
  }

  .btnn {
    font-size: 14px;
    width: 70%;
  }
  .set-c-name {
    font-size: 1rem !important;
   
  }
}

/* ===========BOOTSTRAP & W3 & FONTASOME CSS================================= */
::-webkit-scrollbar {
  width: 5px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e6e3e3;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #338A2A;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #aaa9a9;
}


.fa {
  cursor: pointer;
  font-size: large;
}

/* =============LAYOUT CSS================================= */
.f-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin: auto;
}

.owner-h {
  background: #fff;
  margin-bottom: 10px;
  padding: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

/* .sub-header {
  justify-content: center;
  align-items: center;
  margin: 0 4px;
  padding: 0 10px;
  background: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
} */

.main-div {
  padding: 5px 1px !important;

}

/* .sHort {
  font-weight: 600;
  font-size: 14px;
 
  justify-content: center;
  cursor: pointer;
  border: 1px solid transparent;
  height: 32px;
  text-align: center;
  border-radius: 5px;
  background: #338A2A;
  color: #fff;
} */

.sHort:hover {
  background: #565656;
  border: 1px solid #3a3939;
}

/* =====================mat CSS============= */

.mat-mdc-form-field-infix {
  flex: auto;
  min-width: 0;
  width: 17rem !important;
  position: relative;
  box-sizing: border-box;
  min-height: 43px !important;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding: 10px 0px !important;


}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 20px;
}

.mat-mdc-form-field-flex {

  width: 100% !important;
}

.mat-mdc-slide-toggle .mdc-switch .mdc-switch__handle::before {
  background: #0d0e0e !important;
}

.mat-mdc-slide-toggle .mdc-switch .mdc-switch__handle::before {
  background: #181717 !important;
}



.mat-mdc-dialog-container .mdc-dialog__surface {
  text-align: center;
  display: block;
  width: 450px !important;
  height: 100%;
  padding: 1rem;
}



.mdc-tab {
  min-width: 120px !important;
  background-color: #ceccca;
  font-weight: 500;
  font-size: 14px;
 
  border-radius: 2px
}

/* .mdc-tab:hover {
  background: #338A2A;

}

.mdc-tab--Active {
  background: #338A2A;

} */

.mat-mdc-tab .mdc-tab__text-label,
.mat-mdc-tab-link .mdc-tab__text-label--Active {
  color: #fff !important;
}

.mat-mdc-tab .mdc-tab__text-label,
.mat-mdc-tab-link .mdc-tab__text-label {
  color: #000 !important;
}

.mat-mdc-tab.mdc-tab {
  height: 40px !important;
}

.mat-mdc-form-field:not(.mat-form-field-no-animations) .mdc-floating-label {
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1), color 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.mat-mdc-tab-labels {
  justify-content: center !important;
}

.my-date .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: #fff !important;
  border: 1px solid #338A2A !important;
  height: 40px;
  border-radius: 3px;
}

.multi .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: #fff !important;
  border: 1px solid #565656 !important;
  height: 40px;
  border-radius: 2px;
}



.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgb(12 12 12);
}

.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs>.mat-mdc-tab-header .mat-mdc-tab {
  flex-grow: 0 !important;
}

/* toggle btn */
.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after {
  background: #338A2A !important;
}

.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::after {
  background: #338A2A !important;
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: #000;
}




/* / ---date -piker-- / */

.my-field .mat-mdc-text-field-wrapper {
  width: 100% !important;
  border: 1px solid;
  height: 40px !important;
  border-radius: 3px;
  background: #fff !important;
  border: 1px solid #338A2A;
}

.mat-mdc-form-field-focus-overlay {
  background-color: transparent!important
}

.mdc-floating-label mat-label {
  color: #000 !important;
  font-size: 14px;
 
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: #fff;
}

.mat-elevation-z8 {
  box-shadow: none;
}

element.style {
  transform: none;
}

.mat-mdc-tab-body-content {
  background: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

/* .my-field.mat-mdc-form-field {
  margin-top: 25px;
  width: 100%;
} */

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding: 8px 0 0 13px !important;
}
.my-date .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding: 7px 0 0 0px !important;
  font-size: 13px;
}

.My-Tab .mdc-tab--active {
  background-color: #338A2A !important;
}

.My-Tab .mdc-tab--active .mdc-tab__text-label {
  color: #fff !important;
}

.mdc-notched-outline__leading {
  border-color: #338A2A !important;

}

.mdc-notched-outline__notch {
  border-color: #338A2A !important;
}

.mdc-notched-outline__trailing {
  border-color: #338A2A !important;
}

.mdc-line-ripple {
  display: none !important;
}

.tm .mat-mdc-tab-header {
  margin-top: -4rem;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-bottom: 0px !important;
}

.my-drop .mat-mdc-text-field-wrapper {
  height: 40px !important;
  border: 1px solid #000;
  border-radius: 3px;

}

.mat-mdc-table .mdc-data-table__row:nth-child(odd) {
  height: 50px;
}

.symble {
  display: flex;
  gap: 1rem;
  /* color: #D3843D; */
}

.af.fa {
  color: #f1f1f1;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #338A2A;
}


.mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: #338A2A;
}

.otp-input {
  width: 40px !important;
  height: 40px !important;
  border-radius: 4px;
  border: solid 1px #338A2A !important;
  text-align: center;
  font-size: 20px !important;
  padding-right: 12px !important;
  margin-right: 15px !important;
  /* color: #000 !important; */
}

.mat-datepicker-content .mat-calendar {
  width: 210px !important;
  height: 270px !important;
}
.mat-datepicker-toggle-active.mat-accent {
  color: #338A2A;
}

.mat-mdc-button:not(:disabled) {
  color: #338A2A !important;
}

.set-c-name {
  color: #338A2A;
  font-size: 1.5rem;
  display: flex;
  gap: 1rem;
  align-items: center;
}

.t-show {
  background: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
}

/* nat-radio */
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
  border-color: #338A2A !important;
  background-color: #338A2A !important;

}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:not([disabled]):focus~.mdc-checkbox__ripple {
  opacity: 0 !important;
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #fff;
}

.mdc-text-field .mdc-text-field__input {
  caret-color: var(--mdc-theme-primary, #000);
}

input,
textarea:focus {
  outline-color: #338A2A !important;
  height: 35px;
  width: 100%;
}


.my-in input,
.my-in textarea:focus {
  outline: none !important;
}

.mat-mdc-tab .mdc-tab__text-label {
font-size: 15px !important;
}

.mat-mdc-form-field.mat-accent .mdc-text-field__input {
  caret-color: var(--mdc-theme-secondary, #000);
}

.dash-card:hover {
  /* transform: scale(1.1); */
  margin-top: 15px !important;
  box-shadow: #ccccccad 0px 26px 15px !important;
}

.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #338A2A;
  color: #fff;
}

.mat-calendar-body-selected {
  background-color: #338A2A;
  color: #fff;
}

.mdc-data-table__cell,
.mdc-data-table__header-cell {
  padding: 0px 4px 0 4px !important;
}

.mat-mdc-table {
  white-space: pre !important;
}

.layout1 {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background: #fff;
  margin-top: 2rem;
  padding: 2rem;

}

.mat-mdc-dialog-container .mdc-dialog__surface {
  text-align: center;
  display: block;
  width: 100% !important;
  padding: 1rem;
}

.mat-mdc-form-field-subscript-wrapper {
  box-sizing: border-box;
  width: 100%;
  position: relative;
  display: none;
}

.mdc-dialog .mdc-dialog__content {
  padding: 0px !important;
}

.alignment-table th {
  text-align: center !important;
  margin-top: 1rem;
  background: #338A2A !important;
  color: #fff;
  padding: 0 5px !important;
}

.alignment-table td {
  text-align: center !important;
}


.mat-mdc-paginator {
  position: sticky;
  bottom: 0;
  display: block;
}

.mat-calendar-controls {

  margin: -3% !important;
}

.mdc-data-table__cell,
.mdc-data-table__header-cell {
  border: 1px solid #ccc;
}

.set-search mat-icon {
     transform: translate(-50% -50%);
     position: absolute;
     z-index: 1;
     top: 10%;
     font-size: 30px;
     color: grey;
     margin: 2px;
}

.col-img {
  height: 50px;
  width: 50px;
  margin: 5px;
  margin: auto;
  cursor: pointer;
}


.auth-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px; 
   background: #14141461;
   background-image: url('assets/Sportsbay_Vendor_Web_Panel_Assets/side_panel_bg.png');
   background-repeat: no-repeat;
   background-size: cover;
}


/* CMS MANAGEMENT */
.tab-head {
  padding-bottom: 1rem;
  display: flex;
  justify-content: space-between;

}

.tab-boady {
  margin: 1rem;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.cms-btn {
  color: #fff;
  background: #338A2A;
  width: 70px;
  border-radius: 4px;
  text-align: center;
  margin: 10px auto;
  cursor: pointer;
  padding: 4px;
}

.d-header{
  display: flex;
  justify-content: space-between;
}

.mdc-data-table__row:last-child .mdc-data-table__cell {
  border-bottom: 1px solid #ccc !important;
}


 #groundModal{
  position: absolute;
  top: 20% !important;
}
*************** Modal css start *************/
.modal-header .btn-close:focus{
  box-shadow: none;

}

.modal.fade.show {
  backdrop-filter: blur(4px);
  background-color: #01223770;
}

.modal-backdrop {
    z-index: 0!important;
}

.modal-header{
    padding: 10px 20px;
}

.modal-title{
    font-size: 18px;
    font-weight: 600;
}





@media (min-width:900px) and (max-width:1501px){

  .new-search{
    position: absolute !important;
    top: 18% !important;
}
}
@media (min-width:1501px) and (max-width:1600px){

  .new-search{
    position: absolute !important;
    top: 22% !important;
}
}
